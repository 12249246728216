import {defineStore} from 'pinia'
import {useRepo} from 'pinia-orm'
import {computed} from 'vue'

import useStoreReset from '@/composables/use-store-reset'
import useStoreState from '@/composables/use-store-state'
import User from '@/models/User'
import getUsersMe from '@/services/intranet-api/users/me/get'
import camelCaseKeys from '@/utils/camel-case-keys'

import useAuthenticationLogoutStore from '../authentication/logout'

const initialState = {
  data: null,
  isLoading: false,
  error: null,
}

// eslint-disable-next-line max-statements
const useUsersMeStore = defineStore('usersMe', () => {
  const userRepo = useRepo(User)

  let loadingPromiseResolve = null
  let loadingPromiseReject = null
  let loadingPromise = null

  const resetLoadingPromise = () => {
    loadingPromise = new Promise((resolve, reject) => {
      loadingPromiseResolve = resolve
      loadingPromiseReject = reject
    })
  }

  const state = useStoreState(initialState)

  const getters = {
    entity: computed(() => userRepo.withAll().find(state.data.value)),
    isLoggedIn: computed(async () => {
      try {
        if (!state.data.value) {
          await loadingPromise
        }

        return Boolean(state.data.value)
      } catch (_error) {
        return false
      }
    }),
  }

  const actions = {
    // eslint-disable-next-line max-statements
    load: async () => {
      try {
        resetLoadingPromise()

        state.error.value = null
        state.data.value = null
        state.isLoading.value = true

        const response = await getUsersMe()
        const user = userRepo.save(camelCaseKeys(response.data.data))
        state.data.value = user[user.$primaryKey()]

        state.isLoading.value = false
        loadingPromiseResolve?.()
      } catch (error) {
        state.error.value = error
        state.isLoading.value = false
        loadingPromiseReject?.()
        throw error
      }
    },
    can: async (...args) => {
      try {
        if (!state.data.value) {
          await loadingPromise
        }

        return actions.canSync(...args)
      } catch (_error) {
        return false
      }
    },
    canSync: (action) => {
      const user = getters.entity.value

      if (!user || !action) {
        return false
      }

      if (user.isSuperAdmin) {
        return true
      }

      return user.permissions.some((permission) => permission.name === action)
    },
    $reset: useStoreReset(state, initialState),
  }

  const authenticationLogoutStore = useAuthenticationLogoutStore()
  authenticationLogoutStore.$onAction(({name, after}) => {
    if (name === 'logout') {
      after(() => {
        actions.$reset()
      })
    }
  })

  return {
    ...state,
    ...getters,
    ...actions,
  }
})

export default useUsersMeStore
