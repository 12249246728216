import {Model} from 'pinia-orm'

import Department from './Department'

class Permission extends Model {
  static entity = 'permissionEntities'

  static fields() {
    return {
      id: this.number(),
      name: this.string(),
      guardName: this.string(),
    }
  }
}

class UserPermission extends Model {
  static entity = 'userPermissionEntities'

  static primaryKey = ['userId', 'permissionId']

  static fields() {
    return {
      userId: this.number(),
      permissionId: this.number(),
    }
  }
}

export default class User extends Model {
  static entity = 'userEntities'

  static fields() {
    return {
      id: this.number(),
      name: this.string(),
      location: this.string(),
      email: this.string(),
      departmentId: this.number(),
      department: this.belongsTo(Department, 'departmentId'),
      slackUserId: this.string(),
      slackAvatar: this.string(),
      enabled: this.number(),
      mobile: this.string(),
      phone: this.string(),
      isAdmin: this.boolean(),
      isPm: this.boolean(),
      deletedAt: this.string(),
      office: this.string(),
      isFourDayWorkWeek: this.boolean(),
      isSuperAdmin: this.boolean(),
      isUtilityUser: this.boolean(),
      permissions: this.belongsToMany(Permission, UserPermission, 'userId', 'permissionId'),
      userTaskplanGroupAdminIds: this.attr([]),
    }
  }
}
