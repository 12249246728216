import axios from 'axios'

import {LOCAL_STORAGE_ACCESS_TOKEN_KEY} from '@/constants'
import router from '@/router'
import useUsersMeStore from '@/stores/users/me'
import camelCaseKeys from '@/utils/camel-case-keys'
import isAuthorizationError from '@/utils/is-authorization-error'
import showErrorMessage from '@/utils/show-error-message'

const intranetApiRequest = axios.create({
  baseURL: import.meta.env.VITE_INTRANET_API_BASE,
  transformResponse: [...axios.defaults.transformResponse, (data) => camelCaseKeys(data)],
})

// Add auth token to request headers
intranetApiRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

// Delete auth token on 401 response
intranetApiRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (isAuthorizationError(error)) {
      let from = router.currentRoute.value?.query?.from

      if (router.currentRoute.value.matched.some((record) => record.meta.requiresLogin)) {
        from = router.currentRoute.value.fullPath

        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)

        await router.push({name: 'Login', query: {from}})
        showErrorMessage('You are not logged in.', error)
      }

      useUsersMeStore().$reset()
    }

    throw error
  },
)

export default intranetApiRequest
