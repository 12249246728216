<script setup>
import {computed} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const hasError = computed(() => Boolean(route.query.error))
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.box">
      <template v-if="hasError">
        <div :class="$style.icon">❌</div>
        <h1 :class="$style.text">Auth error</h1>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  height: calc(100 * var(--vh));
  padding: 24px 16px;

  @media (width >= 1440px) {
    padding: 24px 32px;
  }
}

.box {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (width >= 1440px) {
    gap: 48px;
  }
}

.icon {
  font-size: 75px;

  @media (width >= 1440px) {
    font-size: 100px;
  }
}

.text {
  font-size: 48px;
  font-weight: normal;

  @media (width >= 1440px) {
    font-size: 64px;
  }
}
</style>
