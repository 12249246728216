import {Model} from 'pinia-orm'
import {NumberCast} from 'pinia-orm/casts'

export default class Department extends Model {
  static entity = 'departmentEntities'

  static fields() {
    return {
      id: this.number(),
      name: this.string(),
    }
  }

  static casts() {
    return {
      id: NumberCast,
    }
  }
}
